import React, { useState } from 'react';
import { Link, graphql, Script } from 'gatsby';
import {
  LayoutLp,
  SEO,
  AssetImage,
  LWrap,
  HeaderLpCustom,
  CBtnList,
  CBtn,
  CYoutube,
} from '../../../components/_index';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/gardenresort.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  const [openFlag, setOpenFlag] = useState(false);
  const [openFlag02, setOpenFlag02] = useState(false);
  const [openFlag03, setOpenFlag03] = useState(false);
  const [openFlag04, setOpenFlag04] = useState(false);
  const [openFlag05, setOpenFlag05] = useState(false);
  const openClick = (e: any) => {
    setOpenFlag(true);
  };
  const closeClick = (e: any) => {
    setOpenFlag(false);
  };
  const openClick02 = (e: any) => {
    setOpenFlag02(true);
  };
  const closeClick02 = (e: any) => {
    setOpenFlag02(false);
  };
  const openClick03 = (e: any) => {
    setOpenFlag03(true);
  };
  const closeClick03 = (e: any) => {
    setOpenFlag03(false);
  };
  const openClick04 = (e: any) => {
    setOpenFlag04(true);
  };
  const closeClick04 = (e: any) => {
    setOpenFlag04(false);
  };
  const openClick05 = (e: any) => {
    setOpenFlag05(true);
  };
  const closeClick05 = (e: any) => {
    setOpenFlag05(false);
  };

  // const [video, setVideo] = document.querySelector('#video');

  return (
    <>
      {/* <Script
        dangerouslySetInnerHTML={{
          __html: `
            (function(){
              const video = document.querySelector('#video');
              const video_btn = document.querySelector('#video-btn');
              let is_playing = false;
            
              video_btn.addEventListener('click', () => {
                if (!is_playing) {
                  video.play();
                  is_playing = true;
                } else {
                  video.pause();
                  is_playing = false;
                }
              });
              console.log("aaa");
            })(document)
          `,
        }}
      /> */}

      <LayoutLp>
        <SEO
          title={frontmatter?.title}
          description={frontmatter?.description}
          ogimage={`${process.env.BASE_URL}assets/images/sp/gardenresort/ogimage.png`}
        />
        <header className="l_headerLp l_headergreenplan">
          <div className="container">
            <div className="inner">
              <p className="logo">
                <Link to="/">
                  <AssetImage
                    src="/assets/images/sp/gardenresort/logo.svg"
                    alt="THE ROYAL PARK SENDAI"
                    loading="lazy"
                  />
                </Link>
              </p>
            </div>
          </div>
        </header>
        <main>
          <div className="p_greenplan">
            <section className="lp_kv">
              {!isSp ? (
                <figure className="imgWrapper">
                  <AssetImage
                    src="/assets/images/sp/gardenresort/kv.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
              ) : (
                <figure className="imgWrapper">
                  <AssetImage
                    src="/assets/images/sp/gardenresort/kv__sp.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
              )}
            </section>

            <LWrap exClass="u_mbMiddleLarge">
              <div className="lp_hotelUnit">
                <div className="lp_hotelMedia">
                  <figure className="imgWrapper">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel__sp.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </figure>
                </div>
                <div className="lp_hotelBody">
                  <div className="lp_hotelBodyTitle">
                    色づくガーデンで、
                    <br />
                    秋のさわやかな風を感じながら
                    <br />
                    過ごす特別な時間
                  </div>
                </div>
              </div>
              <div className="lp_hotelautumnUnit">
                <div className="lp_hotelautumnMedia">
                  <figure className="imgWrapper">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel02.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel02__sp.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </figure>
                </div>
                <div className="lp_hotelautumnBody">
                  <div className="lp_hotelautumnBodyText">
                  10月からはドリンクフリーフローがついた2食付きプランをご用意。お財布を気にせず贅沢なディナータイムをお過ごしいただけます。また宿泊者限定で、ラウンジのバータイムでの飲食を30%OFFでご利用いただけます。
                  <br />
                  五感のすべてを開放するここにしかない秋の仙台リゾートステイをお得にご堪能ください。
                  </div>
                </div>
              </div>
              <div className="lp_hotelautumnUnit">
                <div className="lp_hotelautumnMedia">
                <figure className="imgWrapper02">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel03.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel03__sp.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </figure>
                </div>
              </div>
              <h3 className="lp_title lp_title_mt60">
                <span>
                  <AssetImage
                    src="/assets/images/sp/gardenresort/img_point_en.png"
                    alt=""
                    loading="lazy"
                  />
                </span>
                おすすめポイント
              </h3>
              <div className="lp_recommendationUnit">
                <div className="lp_recommendationMedia">
                  <div className="imgWrapper">
                    <figure>
                      <AssetImage 
                        src="/assets/images/sp/gardenresort/img_point.png"
                        alt=""
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div className="lp_recommendationBody">
                    <p className="lp_recommendationPoint">Point 01</p>
                    <p className="lp_recommendationTitle">
                    フリーフロー付プランでお財布を気にすることなく贅沢に食事をたのしむ
                    </p>
                  </div>
                </div>
                <div className="lp_recommendationMedia">
                  <div className="imgWrapper">
                    <figure>
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_point02.png"
                        alt=""
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div className="lp_recommendationBody">
                    <p className="lp_recommendationPoint">Point 02</p>
                    <p className="lp_recommendationTitle">
                    2泊3日のアーユルヴェーダ集中プログラムプランで体の調子を整え身も心もリラックス
                    </p>
                  </div>
                </div>
                <div className="lp_recommendationMedia">
                  <div className="imgWrapper">
                    <figure>
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_point03.png"
                        alt=""
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div className="lp_recommendationBody">
                    <p className="lp_recommendationPoint">Point 03</p>
                    <p className="lp_recommendationTitle">
                    ロビーラウンジでガーデンのライトアップを眺めながら宿泊ゲストだけのお得なバータイムを
                    </p>
                  </div>
                </div>
              </div>
              <h3 className="lp_title lp_title_mt60">
                <span>
                  <AssetImage
                    src="/assets/images/sp/gardenresort/img_plan_en.png"
                    alt=""
                    loading="lazy"
                  />
                </span>
                おすすめプラン
              </h3>
              <div className="lp_plan02">
                <div className="lp_plan02List">
                  <div className="lp_plan02ListItem">
                    <div className="lp_plan02ListNumber">
                      <div className="lp_plan02ListNumberPlan">Plan 01</div>
                    </div>
                    <div className="lp_plan02ListContents">
                      <div className="lp_plan02ListName">
                      【ドリンクフリーフロー】” シェフズ テラス” の人気メニューを選りすぐった特別ディナーコース付
                      </div>
                      <div className="lp_plan02ListImg">
                        <a href="http://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_sendai&plan_groupcd=DN24080501&form=jp" target="_blank">
                          {!isSp ? (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan.png"
                              alt=""
                              loading="lazy"
                            />
                          ) : (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan__sp.png"
                              alt=""
                              loading="lazy"
                            />
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lp_plan02List">
                  <div className="lp_plan02ListItem">
                    <div className="lp_plan02ListNumber">
                      <div className="lp_plan02ListNumberPlan">Plan 02</div>
                    </div>
                    <div className="lp_plan02ListContents">
                      <div className="lp_plan02ListName">
                      【ドリンクフリーフロー】中国料理” 桂花苑” 人気メニューを選りすぐった特別ディナーコース付
                      </div>
                      <div className="lp_plan02ListImg">
                        <a href="http://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_sendai&plan_groupcd=DN24081402&form=jp" target="_blank">
                          {!isSp ? (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan02.png"
                              alt=""
                              loading="lazy"
                            />
                          ) : (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan02__sp.png"
                              alt=""
                              loading="lazy"
                            />
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lp_plan02List">
                  <div className="lp_plan02ListItem">
                    <div className="lp_plan02ListNumber">
                      <div className="lp_plan02ListNumberPlan">Plan 03</div>
                    </div>
                    <div className="lp_plan02ListContents">
                      <div className="lp_plan02ListName">
                      【HAPPY ANNIVERSARY】ガーデンのライトアップと楽しむアニバーサリーステイ（ 夕・朝食付き）
                      </div>
                      <div className="lp_plan02ListImg03">
                        <a href="http://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_sendai&plan_groupcd=DN23012501&form=jp" target="_blank">
                          {!isSp ? (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan03.png"
                              alt=""
                              loading="lazy"
                            />
                          ) : (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan03__sp.png"
                              alt=""
                              loading="lazy"
                            />
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lp_plan02List">
                  <div className="lp_plan02ListItem">
                    <div className="lp_plan02ListNumber">
                      <div className="lp_plan02ListNumberPlan">Plan 04</div>
                    </div>
                    <div className="lp_plan02ListContents">
                      <div className="lp_plan02ListName">
                        旅するアーユルヴェーダ【秋の2泊3日集中プログラム】
                      </div>
                      <div className="lp_plan02ListImg03">
                        <a href="https://www.srph.co.jp/aesthetic/aidr2bh9g/" target="_blank">
                          {!isSp ? (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan04.png"
                              alt=""
                              loading="lazy"
                            />
                          ) : (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan04__sp.png"
                              alt=""
                              loading="lazy"
                            />
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h3 className="lp_title">
                <span>
                  <AssetImage
                    src="/assets/images/sp/gardenresort/img_activity_en.png"
                    alt=""
                    loading="lazy"
                  />
                </span>
                アクティビティ
              </h3>
              <div className="lp_limitedUnit">
                <div className="lp_limitedMedia">
                  <div className="imgWrapper">
                    {!isSp ? (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="lp_limitedBody">
                  <p className="lp_limitedTitle">モーニングヨガ</p>
                  <p className="lp_limitedBody">
                  心と身体をやさしく目覚めさせる朝の時間。無理のないヨガのポーズで巡りの良い身体へと導く、ウェルネスライフをおたのしみください。
                  </p>
                  <CBtnList
                    align="left"
                    data={[
                      {
                        label: 'アクティビティの詳細はこちら',
                        link: {
                          href: 'https://www.srph.co.jp/experience/activity/dqags4obj/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="lp_limitedUnit lp_limitedUnit__reverse">
                <div className="lp_limitedMedia">
                  <div className="imgWrapper">
                    {!isSp ? (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity02.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="lp_limitedBody">
                  <p className="lp_limitedTitle">レンタルサイクル</p>
                  <p className="lp_limitedBody">
                  見た目もおしゃれでどなたでもお使いいただきやすい"tokyo bike"の自転車をご用意しました。さわやかな風を感じながら、色づく美しい街並みをおたのしみください。
                  </p>
                  <CBtnList
                    align="left"
                    data={[
                      {
                        label: 'アクティビティの詳細はこちら',
                        link: {
                          href: 'https://www.srph.co.jp/experience/activity/4f3nsye9zzo/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="lp_limitedUnit lp_limitedUnit02">
                <div className="lp_limitedMedia">
                  <div className="imgWrapper">
                    {!isSp ? (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity03.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="lp_limitedBody">
                  <p className="lp_limitedTitle">宙（ソラ）ネタリウム</p>
                  <p className="lp_limitedBody">
                    四季折々の美しい星空をホテルのガーデンから見上げる宙（ソラ）ネタリウム。星のソムリエ®の資格を持つホテルスタッフが季節ごとの星や星座をご紹介します。
                  </p>
                  <CBtnList
                    align="left"
                    data={[
                      {
                        label: 'アクティビティの詳細はこちら',
                        link: {
                          href: 'https://www.srph.co.jp/experience/activity/kami79c6m/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="lp_subTitle02">
                荷物をあずけ、近隣のショッピングモールで
                <br className="u_pc" />
                ⾝軽にショッピング
              </div>
              <div className="lp_lead">
              近隣の「仙台泉プレミアム・アウトレット」や「泉パークタウン タピオ」などでショッピングを。荷物はホテルに預けて、身軽に周れるのが嬉しい。
                <br className='u_pc'/>
                また来たくなるような美しい街並みをたのしみながら、旅の締めくくりに。
              </div>

              <div className="lp_plan">
                <div className="lp_planList">
                  <div className="lp_planListImg">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity04.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity04__sp.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>
                  <CBtnList
                    align="center"
                    data={[
                      {
                        label: '仙台泉プレミアム・アウトレット',
                        link: {
                          href: 'https://www.premiumoutlets.co.jp/sendaiizumi/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
                <div className="lp_planList">
                  <div className="lp_planListImg">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity05.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity05__sp.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>
                  <CBtnList
                    align="center"
                    data={[
                      {
                        label: '泉パークタウン タピオ',
                        link: {
                          href: 'https://www.tapio.jp/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="lp_subTitle02">
                【提携施設】
                <br />
                お得にリフレッシュ！提携温泉施設で心も体も温まる体験を
              </div>
              <div className="lp_lead">
                ホテルの提携施設である「スパメッツァ仙台」を優待価格でご利用できます。
                <br/>
                伊達なサウナやハーブ香る杜の潤いソルトサウナで、旅の疲れをゆったり癒して。
              </div>

              <div className="lp_planspa">
                <div className="lp_planList">
                  <div className="lp_planListImg">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity06.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity06__sp.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>
                  <CBtnList
                    align="center"
                    data={[
                      {
                        label: 'スパメッツァ仙台',
                        link: {
                          href: 'https://ryusenjinoyu.com/spametsasendai/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
              </div>

            </LWrap>
          </div>
        </main>
      </LayoutLp>
    </>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;